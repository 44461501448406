import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const addCourse = createAsyncThunk("addCourse", async (data) => {
  try {
    console.log("Add course data is.............", data);
    const res = await api.post(`${API_URL}/course/add-course`, data);
    console.log("response",res.data);
    return res.data;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});



export const deleteCourse = createAsyncThunk("addCourse", async (courseId) => {
  try {
    console.log("Add course data is",courseId );
    const res = await api.delete(`${API_URL}/course/delete-course/${courseId}`);
    return {
      status: res?.status,
      data: res?.data?.data,
      //   token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const Thumbnailimage = createAsyncThunk("addCourse", async (file) => {
  try {
    console.log("abc thumb image for zain badshs", file)
    const formData = new FormData();
      formData.append("thumbnailImage", file);

      const response = await api.post(`${API_URL}/course/add-thumbnail`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
export const uploadvideo = createAsyncThunk("addCourse", async (file) => {
  try {
    console.log("abc thumb image for zain badshs", file)
    const formData = new FormData();
    formData.append("file", file);

      const response = await api.post(`${API_URL}/course/upload-files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
