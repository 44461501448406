import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ContentLibrary.css";
import { getFolder } from "../../services/redux/middleware/getFolder";
import { useDispatch, useSelector } from "react-redux";
import Addmodel from "../../Components/Addmodel/Addmodel";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

import Dropdown from "react-bootstrap/Dropdown";
import Updatemodel from "../../Components/UpdateFoldermodal/Updatemodel";
import DeletefolderModal from "../../Components/DeleteFolder/DeletefolderModal";
const ContentLibrary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [updateshowModal, setupdateshowModal] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const handleOpenupdateModal = (folderName, folderId) => {
    setSelectedFolderName(folderName);
    setSelectedFolderId(folderId);
    setupdateshowModal(true);
  };

  const handleCloseupdateModal = () => {
    setupdateshowModal(false);
    setSelectedFolderName('');
    setSelectedFolderId('');
  };

  const handleNavigation = () => {
    navigate("/ContentLibraryCards");
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };




  const [folderData, setFolderData] = useState([]);
  const dispatch = useDispatch();
  const FolderData = useSelector(
    (state) => state?.getFolder?.profile?.data?.contentLibrary);
  console.log("FolderData", FolderData)
  useEffect(() => {
    const fetchFolder = async () => {
      setIsLoading(true);
      await dispatch(getFolder());

      setFolderData(FolderData)
      setIsLoading(false);
    };
    fetchFolder();
  }, []);



  // Data array for the content items
  const contentItems = [
    { title: "Twitter Mastery", files: "12 Files" },
    { title: "Instagram Mastery", files: "12 Files" },
    { title: "Twitter Mastery", files: "12 Files" },
    { title: "Instagram Mastery", files: "12 Files" },
    { title: "Instagram Mastery", files: "12 Files" }
  ];

  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="ContentLibraryMainDiv">
          <div className="instadiv">
            <p className="instadivptag">Lectures</p>
            <button className="insta" onClick={handleOpenModal}>
              <img src="/Images/instagram/plus.svg" alt="" />
              Add Folder
            </button>
          </div>

          <div className="Content">
            <div className="ContentLibraryTop">
              {/* Map over the contentItems array */}
              {FolderData?.map((item, index) => (
                <div
                  className="ContentLibraryTop_Div1"
                  tabIndex="0"

                  key={index}
                >

                  <Dropdown className="ddddrop">
                    <Dropdown.Toggle className="dropdownmain11"
                      variant="success"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        color: "white",
                      }}
                    >
                      <img className="deleteiconsss" src="/Images/threedot.svg" alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dddropppppp" style={{ backgroundColor: "#24262B", padding: '20px', border: "none", borderRadius: "8px" }} >
                      <Dropdown.Item href="#/action-1" style={{ color: "white", marginBottom: '15px', padding: '10px 0px', display: "flex", alignItems: "center", gap: "5px" }} className="DropDownmenuOptionDivvvvv"
                        onClick={() => handleOpenupdateModal(item?.folderName, item?._id)}>
                        <img src="/Images/instagram/edit.svg" alt="" />
                        <p className="DropDownmenuOption" >Edit</p>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" style={{ color: "white", padding: '10px 0px', display: "flex", alignItems: "center", gap: "5px" }} className="DropDownmenuOptionDivvvvv"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setSelectedFolderId(item?._id);
                        }}
                      >
                        <img class="deleteiconsss" src="/Images/instagram/deleteimg.svg" alt="" />
                        <p className="DropDownmenuOption" >Delete</p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "24px" }} onClick={handleNavigation}>
                    <img src="/Images/instagram/files.svg" alt="files" />
                    <div className="ContentLibraryTop_Div2">
                      <p className="ContentLibraryTop_Div2_p1">{item?.folderName}</p>
                      <p className="ContentLibraryTop_Div2_p2">{item?.contents?.length}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Addmodel Component */}
          <Addmodel show={showModal} handleClose={handleCloseModal} />
          <Updatemodel show={updateshowModal} handleClose={handleCloseupdateModal} folderNameProp={selectedFolderName} folderIdProp={selectedFolderId} />
          <DeletefolderModal
            show={showDeleteModal}
            handleClose={handleCloseDeleteModal}
            folderId={selectedFolderId}  // Pass the folder ID to the modal
          />
        </div>
      )}
    </>
  );
};

export default ContentLibrary;
