import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { useDispatch } from "react-redux";
import "./InfoContentLibrary.css";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

const InfoContentLibrary = () => {
    const [isLoading, setIsLoading] = useState(false);
  return (
    <>
    {isLoading ? (
      <ScreenLoader/>
    ) : (
      <div className="info__main_containerr">
        <p className="InfoContentLibraryMain1_p">Content Library Info</p>
        <div className="InfoContentLibraryMain1_div">
          <div className="add__course__input_container">
            <p className="infocontact__inputlabel">Title</p>
            <input
              className="infocontent__input_placeholder"
              type="text"
              placeholder="Enter Title"
            />
          </div>

          <div className="add__course__input_container">
            <p className="infocontact__inputlabel">Description</p>
            <textarea
              className="info_content__input_placeholder_description"
              type="text"
              placeholder="Enter Description"
            />
          </div>

          <div className="add__course__input_container">
            <p className="infocontact__inputlabel">Caption 1</p>
            <textarea
              className="info_content__input_placeholder_description"
              type="text"
              placeholder="Enter"
            />
          </div>

          <div className="add__course__input_container">
            <p className="infocontact__inputlabel">Caption 2</p>
            <textarea
              className="info_content__input_placeholder_description"
              type="text"
              placeholder="Enter"
            />
          </div>

          <div className="add__course__input_container">
            <p className="add__course__inputlabel">Caption 3</p>
            <textarea
              className="add__course__input_placeholder_description"
              type="text"
              placeholder="Enter"
            />
          </div>

          <div className="add__course__input_container">
            <p className="infocontact__inputlabel">Thumbnail Media</p>
            <div className="image__dropzone__main">
              {/* {thumbnailImage ? (
                  <img
                    src={URL.createObjectURL(thumbnailImage)}
                    alt="Uploaded"
                    className="uploaded-image"
                  />
                ) : ( */}
              <DropzoneArea
                filesLimit={1}
                acceptedFiles={["image/*"]}
                maxFileSize={5000000}
                showAlerts={["error", "info"]}
                dropzoneText="Upload Media"
              />
            </div>

          
          </div>


       
        </div>


        <div className="publish__button__main__containerr">
              <button className="publish___buttons_main">Publish</button>
              <button className="cancel___buttons_main">Cancel</button>
            </div>
      </div>
    )}
  </>
  );
};

export default InfoContentLibrary;
