import React from 'react'
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const DeleteLectureModel = ({ show, handleClose }) => {



    
  return (
<Modal
      className="modal__main__container"
      show={show}
      onHide={handleClose}
      centered
      backdropClassName="modal-backdrop"

      //   style={{
      //     width: "100%",
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "center",
      //   }}
    >
      <ModalBody className="deeletemodel">
        <ModalHeader className="close__image_container-1">
          <div className="close__image_container" onClick={handleClose}>
            <img src="/Images/instagram/cross.svg" alt="Close" />
          </div>
        </ModalHeader>
        <div className="delete__task__modal_container">
          <div className="delete__task__modal__container_1">
            <div className="delete__task__modal__heading_container">
              <img
                className="logo__modal__image"
                src="/Images/instagram/deleteModel.svg"
                alt="image"
              />

              <p className="delete__task_text">Delete Content Library</p>

              <p className="delete__task_text_P">Are you sure you want to delete this library?</p>
            </div>
            <div className="delete__modal__button_container">
              <button
                onClick={handleClose}
                className="delete__modal_cancel_button"
              >
                Cancel
              </button>
              <button className="delete__modal_yes_button">Yes I’m Sure</button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteLectureModel
