import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./TwitterCourses.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteTwittermodel from "../../Components/DeleteTwittermodel/DeleteTwittermodel";
import { getTwitterCourses } from "../../services/redux/middleware/getTwitterCourses";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

const TwitterCourses = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [twitterCourses, setTwitterCourses] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const navigate = useNavigate();
  const handleOpenDeleteModal = (lectureId) => {
    setSelectedLecture(lectureId);
    console.log("id of selected course", lectureId);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const dispatch = useDispatch();
  const twitterCoursesData = useSelector(
    (state) => state?.getTwitterCourses?.getTwitterCoursessData?.data
  );

  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      
      await dispatch(getTwitterCourses("Twitter"));
      setIsLoading(false);
    };

    fetchCourses();
  }, [dispatch]);

  console.log(twitterCoursesData)

  useEffect(() => {
    if (twitterCoursesData) {
      setTwitterCourses(twitterCoursesData);
    }

    // console.log(twitterCoursesData)
  }, [twitterCoursesData]);

  const handleAddCourse = () => {
  

    navigate("/AddCourse", { state: { type: "Twitter" } });
  }

  // console.log("Twitter courses data is...", twitterCoursesData);
  return (
    <>
      <div
        className="coursesmain"
        style={{
          backgroundColor: "#121212",
          minHeight: "100vh",
          padding: "20px",
        }}
      >
        <div className="instadiv">
          <p className="instadivptag">Twitter Courses</p>
          <button onClick={handleAddCourse} className="insta">
            <img src="/Images/instagram/plus.svg" alt="" />
            Add Course
          </button>
        </div>

        {/* Responsive Table */}
        <TableContainer
          component={Paper}
          className="InstagramCourses_tableMain"
        >
          <Table sx={{ minWidth: 950 }} aria-label="simple table">
            <TableHead className="InstagramCourses_tableMain_head">
              <TableRow>
                <TableCell className="InstagramCourses_tableMain_cell1">
                  Image
                </TableCell>
                <TableCell className="InstagramCourses_tableMain_cell1">
                  Title
                </TableCell>
                <TableCell className="InstagramCourses_tableMain_cell1">
                  Description
                </TableCell>
                <TableCell className="InstagramCourses_tableMain_cell1">
                  Sections
                </TableCell>
                {/* <TableCell className="InstagramCourses_tableMain_cell1">
                  Duration
                </TableCell> */}
                <TableCell className="InstagramCourses_tableMain_cell1">
                  Date
                </TableCell>
                <TableCell className="InstagramCourses_tableMain_cell1">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <ScreenLoader />
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{
                      height: "16px",
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      padding: "0px",
                    }}
                  ></TableCell>
                </TableRow>

                {twitterCourses?.courses?.map((row, index) => (
                  <TableRow key={index} className="InstagramCoursesTable_row">
                    <TableCell className="InstagramCoursesTable_row_cell">
                      <img
                        src={row?.thumbnailImage}
                        alt=""
                        variant="square"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "8px",
                          border: "1px solid #0F6FFF",
                        }}
                      />
                    </TableCell>
                    <TableCell className="InstagramCoursesTable_row_cell">
                      {row?.title}
                    </TableCell>
                    <TableCell className="InstagramCoursesTable_row_cell1">
                    <p
                    
                    dangerouslySetInnerHTML={{ __html: row?.description}} />
                    </TableCell>
                    <TableCell className="InstagramCoursesTable_row_cell1">
                      {row?.sections.length}
                    </TableCell>
                    {/* <TableCell className="InstagramCoursesTable_row_cell1">
                      {row?.courseDuration}
                    </TableCell> */}
                    <TableCell
                      style={{ whiteSpace: "nowrap" }}
                      className="InstagramCoursesTable_row_cell1"
                    >
                      {row?.createdAt.split("T")[0]}
                    </TableCell>
                    <TableCell className="InstagramCoursesTable_row_cell">
                      <div className="InstagramCoursesTable_row_cell2">
                        <img
                          src="/Images/instagram/deleteicon.svg"
                          alt=""
                          onClick={() => handleOpenDeleteModal(row?._id)}
                          style={{ cursor: "pointer" }}
                        />

                        <img src="/Images/instagram/write.svg" alt="" />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {/* Delete Modal */}
        <DeleteTwittermodel
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          taskId={selectedLecture}
        />
      </div>
    </>
  );
};

export default TwitterCourses;
