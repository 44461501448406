import React, { useState, useRef } from "react";
import "./ContentLibraryCards.css";
import DeleteLectureModel from "../../Components/DeleteLectureModel/DeleteLectureModel";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
const ContentLibraryCards = () => {
  const videoRefs = useRef([]);
  const [visibleVideos, setVisibleVideos] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const navigate = useNavigate();
  const handlePlayVideo = (index) => {
    // Make the video visible
    setVisibleVideos((prev) => ({ ...prev, [index]: true }));
    const video = videoRefs.current[index];
    if (video) {
      video.play();
    }
  };

  const handleDeleteClick = (index) => {
    setSelectedLecture(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLecture(null);
  };

  const handleAddCourse = () => {
  

    navigate("/InfoContentLibrary", { state: { type: "Instagram" } });
  }



  
  const items = [
    { type: "image", link: "/Images/instagram/roport1.svg" },
    {
      type: "video",
      link: "/Images/instagram/1.mp4",
      thumbnail: "/Images/instagram/roport3.svg",
    },
    { type: "image", link: "/Images/instagram/roport1.svg" },
    {
      type: "video",
      link: "https://www.w3schools.com/html/movie.mp4",
      thumbnail: "/Images/instagram/roport3.svg",
    },
    { type: "image", link: "/Images/instagram/roport1.svg" },
    {
      type: "video",
      link: "/Images/instagram/1.mp4",
      thumbnail: "/Images/instagram/roport3.svg",
    },
    { type: "image", link: "/Images/instagram/roport1.svg" },
    {
      type: "video",
      link: "/Images/instagram/1.mp4",
      thumbnail: "/Images/instagram/roport3.svg",
    },
  ];







  return (
    
    <div className="Contentc">


<div className="addcontentbtndiv"   onClick={handleAddCourse}>
  <p> </p>
<button className="addcontentbtn">
  <img src="/Images/AddCourse/plus.svg" alt=""  className="plusi" />
 Add Content
</button>

</div>
      
      <div className="containerr">
        {items.map((item, index) => (
          <div key={index} className="itemm"              tabindex="0">
            {item.type === "image" ? (
              <img src={item.link} alt="Item" className="image" />
            ) : (
              <div className="videowrapper1">
                {/* Show the video only if it has been clicked, otherwise show the thumbnail */}
                {visibleVideos[index] ? (
                  <video
                    src={item.link}
                    className="videos"
                    ref={(el) => (videoRefs.current[index] = el)}
                    controls
                    autoPlay
                  />
                ) : (
                  <div className="play-button">
                    <img
                      src={item.thumbnail}
                      alt="Video Thumbnail"
                      className="thumbnail-image"
                    />
                    <button
                      className="playB"
                      onClick={() => handlePlayVideo(index)}
                    >
                      <img src="/Images/instagram/play.svg" alt="play" />
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className="ContentLibraryCards_div2">
              <p className="ContentLibraryCards_div2_ptag">Instagram Mastery</p>



              {/* <img    className="deleteiconsss" src="/Images/instagram/deleteimg.svg" alt=""    
                 onClick={() => handleDeleteClick(index)}/> */}


<Dropdown className="ddddrop">
                          <Dropdown.Toggle
                            className="dropdownmain11"
                            variant="success"
                            style={{
                              backgroundColor: "#393C43",
                              borderColor: "#393C43",
                              color: "white",
                            }}
                          >
                            <img
                              className="deleteiconsss"
                              src="/Images/AddCourse/3dotimg.svg"
                              alt=""
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dddropppppp"
                            style={{
                              backgroundColor: "#24262B",
                              padding: "20px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                          >
                            <Dropdown.Item
                              href="#/action-1"
                              style={{
                                color: "white",
                                marginBottom: "15px",
                                padding: "0px",
                              }}
                              className="DropDownmenuOptionDivvvvv"

                            >
                              <img src="/Images/AddCourse/edit1.svg" alt="" />
                              <p className="DropDownmenuOption">Edit</p>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              style={{ color: "white", padding: "0px" }}
                              className="DropDownmenuOptionDivvvvv"
                        onClick={() => handleDeleteClick(index)}
                            >
                              <img src="/Images/AddCourse/delete1.svg" alt="" />
                              <p className="DropDownmenuOption">Delete</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>


            </div>

            <p className="ContentLibraryCards_p">
              Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>

            <div className="ContentLibraryCards_lastdiv">
              <img src="/Images/instagram/clock.svg" alt="" />
              <p className="ContentLibraryCards_lastdivPtag">
                Dec 14, 2024 - 5 PM
              </p>
            </div>
          </div>
        ))}
      </div>


      {/* Delete Lecture Modal */}
      <DeleteLectureModel show={showModal} handleClose={handleCloseModal} />



    </div>
  );
};

export default ContentLibraryCards;
