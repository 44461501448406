import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { DropzoneArea } from "material-ui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./addCourse.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  addCourse,
  Thumbnailimage,
  uploadvideo,
} from "../../../services/redux/middleware/addCourse";
import ScreenLoader from "../../../Components/ScreenLoader/ScreenLoader";
function AddCourse() {
  const navigate = useNavigate();
  const [selectedItemTarget, setSelectedItemTarget] = useState("Select");
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(1000);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [previewimage, setpreviewimage] = useState();
  // const [courseType, setCourseType] = useState();
  const [whatYouWillLearn, setWhatYouWillLearn] = useState("");
  const [whyTakeThisCourse, setWhyTakeThisCourse] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [thumbnailImage1, setThumbnailImage] = useState();
  const [whoShouldEnroll, setwhoShouldEnroll] = useState();

  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [courseDuration, setCourseDuration] = useState("");
  const [videoPreviewUrls, setVideoPreviewUrls] = useState({});
  const location = useLocation();
  const { type } = location.state || {};
  // setCourseType(type);
  console.log("type is........", type)
  const [sections, setSections] = useState([
    {
      title: "",
      content: "",
      subSections: [
        {
          title: "",
          content: "",
        },
      ],
    },
  ]);
  const handleSelectTarget = (audience) => {
    setTargetAudience(audience);
    setSelectedItemTarget(audience);
  };
  const addSection = () => {
    setSections((prev) => [
      ...prev,
      {
        title: "",
        content: "",
        subSections: [
          {
            title: "",
            content: "",
          },
        ],
      },
    ]);
  };
  const modules = {
    toolbar: [

      ['bold', 'italic', 'underline', 'strike'], // bold, italic, etc.
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // lists


    ]
  };


  const addSubSection = (sectionIndex) => {
    setSections((prev) => {
      const updatedSections = prev.map((section, idx) => {
        if (idx === sectionIndex) {
          return {
            ...section,
            subSections: [
              ...section.subSections,
              {
                title: "",
                content: "",
              },
            ],
          };
        }
        return section;
      });
      return updatedSections;
    });
  };
  const deleteSubSection = (sectionIndex, subSectionIndex) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      if (subSectionIndex > 0) {
        updatedSections[sectionIndex].subSections.splice(subSectionIndex, 1);
      }
      return updatedSections;
    });
  };

  const handleSectionInputChange = (index, key, value) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      updatedSections[index][key] = value;
      return updatedSections;
    });
  };

  const handleSubSectionInputChange = (
    sectionIndex,
    subSectionIndex,
    key,
    value
  ) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      updatedSections[sectionIndex].subSections[subSectionIndex][key] = value;
      return updatedSections;
    });
  };

  const [url, seturl] = useState();
  const [check, setcheck] = useState(false);

  // const handleVideoChange = (sectionIndex, subSectionIndex, files) => {
  //   if (!files || !(files instanceof Blob)) {
  //     console.error("Invalid file:", files);
  //     return;
  //   }
  //   console.log("video", files);
  //   setIsLoading(true);
  //   const videoURL = URL.createObjectURL(files);

  //   setVideoPreviewUrls((prev) => ({
  //     ...prev,
  //     [`${sectionIndex}_${subSectionIndex}`]: videoURL,
  //   }));

  //   console.log("Files received:", files);
  //   dispatch(uploadvideo(files)).then((res) => {
  //     console.log("rdispatching", res?.payload?.fileUrl);
  //     seturl(res?.payload?.fileUrl);
  //     setcheck(!check);
  //     setIsLoading(false);
  //   })
  //     .catch((error) => {
  //       console.error("Upload error:", error);
  //       setIsLoading(false);
  //     });

  //   setPendingUpdate({ sectionIndex, subSectionIndex });

  // };

  // const [pendingUpdate, setPendingUpdate] = useState(null);

  // useEffect(() => {
  //   console.log("checkingggg", url);
  //   if (pendingUpdate && url) {
  //     const { sectionIndex, subSectionIndex } = pendingUpdate;

  //     setSections((prevSections) => {
  //       const updatedSections = [...prevSections];

  //       if (
  //         updatedSections[sectionIndex] &&
  //         updatedSections[sectionIndex].subSections[subSectionIndex]
  //       ) {
  //         updatedSections[sectionIndex].subSections[subSectionIndex].content =
  //           url;
  //         console.log(
  //           "abc",
  //           updatedSections[sectionIndex].subSections[subSectionIndex].content
  //         );
  //       }
  //       console.log("updated section", updatedSections);

  //       return updatedSections;
  //     });
  //     setPendingUpdate(null);
  //   }
  // }, [check]);

  const handleVideoChange = (sectionIndex, subSectionIndex = null, files) => {
    if (!files || !(files instanceof Blob)) {
      console.error("Invalid file:", files);
      return;
    }

    console.log("video", files);
    setIsLoading(true);
    const videoURL = URL.createObjectURL(files);

    const previewKey = subSectionIndex !== null
      ? `${sectionIndex}_${subSectionIndex}`
      : `section_${sectionIndex}`;

    setVideoPreviewUrls((prev) => ({
      ...prev,
      [previewKey]: videoURL,
    }));

    console.log("Files received:", files);
    dispatch(uploadvideo(files))
      .then((res) => {
        console.log("Dispatching", res?.payload?.fileUrl);
        seturl(res?.payload?.fileUrl);
        setcheck(!check);
        setIsLoading(false);

        // Set the pending update for either section or subsection
        setPendingUpdate({ sectionIndex, subSectionIndex });
      })
      .catch((error) => {
        console.error("Upload error:", error);
        setIsLoading(false);
      });
  };
  const [pendingUpdate, setPendingUpdate] = useState(null);
  useEffect(() => {
    if (pendingUpdate && url) {
      const { sectionIndex, subSectionIndex } = pendingUpdate;

      setSections((prevSections) => {
        const updatedSections = [...prevSections];

        if (subSectionIndex !== null) {

          if (
            updatedSections[sectionIndex] &&
            updatedSections[sectionIndex].subSections[subSectionIndex]
          ) {
            updatedSections[sectionIndex].subSections[subSectionIndex].content = url;
          }
        } else {

          if (updatedSections[sectionIndex]) {
            updatedSections[sectionIndex].content = url;
          }
        }
        return updatedSections;
      });

      setPendingUpdate(null);
    }
  }, [check, pendingUpdate, url]);


  const handleSubmit = async (e) => {

    console.log("sections vala purza", sections);

    console.log(sections);
    e.preventDefault();

    const thumbnailImage = thumbnailImage1;

    console.log("submitt vala purza", thumbnailImage);
    const courseType = type;
    console.log("courseType", courseType);
    const data = {
      title,
      description,
      price,
      courseType,
      whatYouWillLearn,
      whyTakeThisCourse,
      whoShouldEnroll,
      targetAudience,
      thumbnailImage,
      sections,
    };
    const isDataComplete = Object.values(data).every((field) => {
      return field !== null && field !== undefined && field !== '';
    });
    if (isDataComplete) {
      // setIsLoading(true);
      setLoading(true);
      console.log("Data to submit: before dispathing_______________", data);
      dispatch(addCourse(data)).then((res) => {
        // setIsLoading(false);
        setLoading(false);
        if (
          res?.payload?.message ===
          "Course with sections and subsections added successfully"
        ) {
          toast.success("Course added successfully", {
            position: "top-right",
            autoClose: 3000,

          });
          setTimeout(() => {
            navigate("/InstagramCourses");
          }, 3000);
        }
      });
    }
    else {
      toast.warning("fill all fields!", {
        position: "top-right",
        autoClose: 3000,
        paddingLeft: "15px"
      });
    }
  };

  const handleImageChange = (files) => {

    if (files[0]) {
      setIsLoading(true);
      const imageUrl = URL.createObjectURL(files[0]);
      setpreviewimage(imageUrl)
      console.log("abc thumb image for usman badshs", files[0]);

      dispatch(Thumbnailimage(files[0])).then((res) => {
        setThumbnailImage(res?.payload?.imageUrl);
        console.log("image response", res?.payload?.imageUrl);
        setIsLoading(false);


      }).catch((error) => {
        console.error("Upload error:", error);
        setIsLoading(false);
      });

    }

  };
  const handleCancelClick = () => {
    navigate(-1);
  };
  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="add__course__main_container">
          <p className="general__course__text">General Course Info</p>

          <div>
            <div className="add__course__container">
              <div className="add__course__input_container">
                <p className="add__course__inputlabel">Title</p>
                <input
                  className="add__course__input_placeholder"
                  type="text"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      setTitle(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="add__course__input_container">
                <p className="add__course__inputlabel">Description</p>
                <ReactQuill
                  className="add__course__input_placeholder_description"
                  placeholder="Enter Description"
                  value={description}
                  modules={modules}
                  onChange={(value) => {
                    if (value.length <= 200) {
                      setDescription(value);
                    }
                  }}
                />
              </div>
              <div className="add__course__input_container">
                <p className="add__course__inputlabel">What You'll Learn</p>
                <ReactQuill
                  className="add__course__input_placeholder_description"
                  value={whatYouWillLearn}
                  modules={modules}
                  onChange={(value) => {
                    if (value.length <= 200) {
                      setWhatYouWillLearn(value);
                    }
                  }}

                  placeholder="Enter"
                  style={{
                    border: 'none',
                  }}
                />
              </div>
              <div className="add__course__input_container">
                <p className="add__course__inputlabel">Why Take This Course</p>

                <ReactQuill
                  className="add__course__input_placeholder_description"
                  placeholder="Enter"
                  value={whyTakeThisCourse}
                  modules={modules}
                  onChange={(value) => {
                    if (value.length <= 200) { setWhyTakeThisCourse(value) }
                  }}

                />
              </div>


              <div className="add__course__input_container">
                <p className="add__course__inputlabel">Who should enroll</p>

                <ReactQuill
                  className="add__course__input_placeholder_description"
                  placeholder="Enter"
                  value={whoShouldEnroll}
                  modules={modules}
                  onChange={(value) => {
                    if (value.length <= 200) { setwhoShouldEnroll(value) }
                  }}
                />
              </div>
              <div className="add__course__input_container">
                <p className="add__course__inputlabel">Target Audience</p>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown__main__container_inner"
                    id="dropdown-basic"
                    style={{
                      outline: 'none',
                      boxShadow: 'none',
                      backgroundColor: '#24262B',
                    }}
                  >
                    <span className="dropdown__text__1">
                      {selectedItemTarget}
                    </span>
                    <img
                      src="/Images/AddCourse/dropdown.svg"
                      alt="Dropdown Icon"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown__list__container">
                    <Dropdown.Item
                      className="dropdown__item__text"
                      onClick={() => handleSelectTarget("Beginners")}
                    >
                      Beginners
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown__item__text"
                      onClick={() => handleSelectTarget("Professionals")}
                    >
                      Professionals
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown__item__text_1"
                      onClick={() => handleSelectTarget("Advanced")}
                    >
                      Advanced
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="add__course__input_container">
                <p className="add__course__inputlabel">Thumbnail Image</p>
                <div className="image__dropzone__main">

                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    maxFileSize={5000000}
                    showAlerts={["error", "info"]}
                    dropzoneText="Upload Image"
                    onChange={handleImageChange}
                  />
                  {previewimage && (
                    <div  style={{ width: "100%" }} className="imageselected">

                      <img
                        src={previewimage}
                        alt="Selected"
                        style={{ width: "100%", borderRadius: "16px" }}
                      />
                    </div>
                  )}

                </div>
              </div>

            </div>
            <div className="course-sections">
              {sections.map((section, sectionIndex) => (
                <div
                  className="section-container"
                  key={`section-${sectionIndex}`}
                >
                  <div className="add__course__container">
                    <p className="course__section_info_text">
                      Course Section Info
                    </p>
                    <div className="add__course__input_container">
                      <p className="add__course__inputlabel">Section Title</p>
                      <input
                        className="add__course__input_placeholder"
                        type="text"
                        placeholder="Enter Section Title"
                        value={section.title}
                        onChange={(e) => {
                          if (e.target.value.length <= 20) {
                            handleSectionInputChange(
                              sectionIndex,
                              "title",
                              e.target.value
                            )
                          }
                        }}
                      />
                    </div>
                    <div className="add__course__input_container">
                      <p className="add__course__inputlabel">
                        Lecture Video
                      </p>
                      <div className="image__dropzone__main">

                        <DropzoneArea
                          id={`upload_video_section_${sectionIndex}`}
                          acceptedFiles={["video/*"]}
                          maxFileSize={50000000000}
                          showAlerts={["error", "info"]}
                          dropzoneText="Upload Video"
                          onChange={(files) =>
                            handleVideoChange(sectionIndex, null, files[0])
                          }
                          onDropRejected={(files) =>
                            alert("File rejected: " + files[0]?.name)
                          }
                          dropzoneClass="image__dropzone__main"
                        />
                        {videoPreviewUrls[`section_${sectionIndex}`] && (
                          <video
                            controls
                            width="100%"
                            src={videoPreviewUrls[`section_${sectionIndex}`]}
                            className="videopreview_llll"
                          >
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                    </div>

                  </div>

                  <div className="subsections-container">
                    {section.subSections.map((subSection, subIndex) => (
                      <div
                        className="add__course__container"
                        key={`subSection-${sectionIndex}-${subIndex}`}
                      >
                        <p className="course__section_info_text">
                          Sub Section {subIndex + 1}
                        </p>
                        <div className="add__course__input_container">
                          <p className="add__course__inputlabel">
                            SubSection Title
                          </p>
                          <input
                            className="add__course__input_placeholder"
                            type="text"
                            placeholder="Enter SubSection Title"
                            value={subSection.title}
                            onChange={(e) => {
                              if (e.target.value.length <= 20) {
                                handleSubSectionInputChange(
                                  sectionIndex,
                                  subIndex,
                                  "title",
                                  e.target.value
                                )
                              }
                            }}
                          />
                        </div>
                        <div className="add__course__input_container">
                          <p className="add__course__inputlabel">
                            Lecture Video
                          </p>
                          <div className="image__dropzone__main">
                            {/* //{subSection.content[0] instanceof File ? ( */}
                            {/* // <video */}
                            {/* //   src={URL.createObjectURL(subSection.content[0])}
                          //   controls
                          //   className="uploaded-video"
                          // />
                        // ) : ( */}
                            <DropzoneArea
                              id={`upload_video_${sectionIndex}_${subIndex}`}
                              acceptedFiles={["video/*"]}
                              maxFileSize={50000000000}
                              showAlerts={["error", "info"]}
                              dropzoneText="Upload Video"
                              onChange={(files) =>
                                handleVideoChange(
                                  sectionIndex,
                                  subIndex,
                                  files[0]
                                )
                              }
                              onDropRejected={(files) =>
                                alert("File rejected: " + files[0]?.name)
                              }
                              dropzoneClass="image__dropzone__main"
                            />
                            {/* )} */}

                            {videoPreviewUrls[
                              `${sectionIndex}_${subIndex}`
                            ] && (
                                <video
                                  controls
                                  width="100%"
                                  src={
                                    videoPreviewUrls[
                                    `${sectionIndex}_${subIndex}`
                                    ]
                                  }
                                  className="videopreview_llll"
                                >
                                  Your browser does not support the video tag.
                                </video>
                              )}
                          </div>
                        </div>
                        {subIndex > 0 && (
                          <button
                            className="delete_subsection_button"
                            onClick={() =>
                              deleteSubSection(sectionIndex, subIndex)
                            }
                          >
                            <div className="delete_subsection_button_container">
                              <img
                                src="/Images/AddCourse/deleteIcon.svg"
                                alt="deleteIcon"
                              />
                              <p className="add_subsection_text">
                                Delete Sub Section
                              </p>
                            </div>
                          </button>
                        )}
                        <button
                          className="add_subsection_button"
                          onClick={() => addSubSection(sectionIndex)}
                        >
                          <div className="add_subsection_button_container">
                            <img
                              src="/Images/AddCourse/addicon.svg"
                              alt="addIcon"
                            />
                            <p className="add_subsection_text">
                              Add Sub Section
                            </p>
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="add__section__button" onClick={addSection}>
            <div className="add_subsection_button_container">
              <img src="/Images/AddCourse/addicon.svg" alt="addIcon" />
              <p className="add_subsection_text">Add Section</p>
            </div>
          </button>
          <div className="publish__button__main__container">
            <button onClick={handleSubmit} className="publish___button_main">
              {Loading ? (
                <div className="loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <p>Publish</p>
              )}

            </button>
            <button
              onClick={handleCancelClick}
              className="cancel___button_main"
            >
              Cancel
            </button>
          </div>
        </div>
      )} 
      <ToastContainer autoClose={10000}
        position="top-right" />
    </>
  );
}

export default AddCourse;
