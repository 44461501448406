import React, { useState } from "react";
import "./Signin.css";
import { useNavigate } from "react-router-dom";
const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();


  const dummyEmail = "admin@gmail.com";
  const dummyPassword = "admin";

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = () => {
    if (email && password) {
      if (email.toLowerCase() === dummyEmail.toLowerCase() && password.toLowerCase() === dummyPassword) {

        navigate("/InstagramCourses");
      } else {

        setErrorMessage("Invalid email or password. Please try again!");
      }
    }
    else {
      setErrorMessage("Please fill all fields!");
    }
  };

  return (
    <div className="SignUpMainNext">
      <img className="SignUpImgNext" src="/Images/SignupImg/hero.png" alt="" />
      <div className="SignUpMain11Next">
        <div className="SignUpMain1Next">
          <div className="SignUpMain1_1Next">
            <img className="SignUpMain1_1iconNext" src="/Images/SignupImg/icon.svg" alt="...." />
            <p className="SignUpMain1_1ptagNext">Welcome Back!</p>
          </div>

          <div className="SignUpMain1_2Next">
            <div className="SignUpMain1_2_firstNext">
              <p className="SignUpMain1_2_first_PNext">Email Address</p>
              <div className="SignUpMain1_2_first_DivNext">
                <img src="/Images/SignupImg/email.svg" alt="...." />
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="SignUpMain1_2_first_Div_TNext"
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="SignUpMain1_2_firstNext">
              <p className="SignUpMain1_2_first_PNext">Password</p>
              <div className="SignUpMain1_2_first_DivNext">
                <img src="/Images/SignupImg/password.svg" alt="...." />
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="SignUpMain1_2_first_Div_TNext"
                  placeholder="Enter Password"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="togglePasswordBtnNext"
                >
                  {showPassword ? (
                    <img src="/Images/SignupImg/showpassword.svg" alt="Hide Password" />
                  ) : (
                    <img src="/Images/SignupImg/hidepassword.svg" alt="Show Password" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {errorMessage && <p style={{ color: "red", paddingTop: "10px", fontSize: "14" }} className="SignUpMain1_2_first_Div_TNext">{errorMessage}</p>}

          <button className="SignUpMain1_2BtnNext" onClick={handleSignIn}>Sign In</button>
        </div>
      </div>


    </div>
  );
};

export default Signin;
