import React, { useState } from "react";
import "./Addmodel.css";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addFolder } from "../../services/redux/middleware/addFolder";
import { ToastContainer, toast } from 'react-toastify';
import { getFolder } from "../../services/redux/middleware/getFolder";
const Addmodel = ({ show, handleClose }) => {
  const [folderName, setFolderName] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddFolder = () => {
    const data = {
      folderName: folderName,
    };
    dispatch(addFolder(data)).then(async(res) => {
      console.log("responseaddFolder", res?.payload?.message);
      if (res?.payload?.message) {
        console.log("yesssssss");
        // toast.success(res?.payload?.message); 
        await dispatch(getFolder()); 
      }
      handleClose();
    });

    
  };

  return (
    <Modal
      className="modal__main__container"
      show={show}
      onHide={handleClose}
      centered
      backdropClassName="modal-backdrop"

  
    >
      <ModalBody className="addmodell">
        <ModalHeader className="close__image_container-1">
          <div className="close__image_container" onClick={handleClose}>
            <img src="/Images/instagram/cross.svg" alt="Close" />
          </div>
        </ModalHeader>
        <div className="delete__task__modal_container">
          <div className="add__task__modal__container_1">
            <div className="delete__task__modal__heading_container">
              <img
                className="logo__modal__im"
                src="/Images/instagram/Addfolder.svg"
                alt="image"
              />

              <p className="delete__task_text">Add Folder</p>
              <div className="Folder__Namediv"></div>






            </div>


            <div className="ffolderdiv">
              <p className="Add_folderP">Folder Name</p>
              <input
                className="inputEnterfolder"
                type="text"
                placeholder="Enter Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </div>

            <div className="add__modal__button_container">
              <button
                onClick={handleClose}
                className="add__modal__button_container_cancel"
              >
                Cancel
              </button>
              <button className="delete__modal_yes_button_add" onClick={handleAddFolder}>Add</button>
            </div>
          </div>
        </div>
        
      </ModalBody>
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />
    </Modal>
  );
};

export default Addmodel;
