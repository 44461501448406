
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import SideDrawer from "./Components/Drawer/SideDrawer";
import IntagramCourse from "./Pages/instagramCourse/intagramCourse";
import AddCourse from "./Pages/instagramCourse/AddCourse/addCourse";
import InstagramCourses from "./Pages/InstagramCourses/InstagramCourses";
import TwitterCourses from "./Pages/TwitterCourses/TwitterCourses";
import DeleteModal from "./Components/DeleteModal/DeleteModal";
import ContentLibrary from './Pages/ContentLibrary/ContentLibrary';
import ContentLibraryCards from './Pages/ContentLibraryCards/ContentLibraryCards';
import Signin from "./Pages/Signin/Signin";
import InfoContentLibrary from "./Pages/InfoContentLibrary/InfoContentLibrary";

function App() {
  const DashboardLayout = () => (
    <>
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <Outlet />
      </SideDrawer>
    </>
  );
  return (
    <Router>




      <Routes element={<DashboardLayout />}>
        <Route element={<DashboardLayout />}>
          <Route path="/IntagramCourse" element={<IntagramCourse />} />
          <Route path="/AddCourse" element={<AddCourse />} />
        
        <Route path="/InstagramCourses" element={<InstagramCourses />} />
        <Route path="/TwitterCourses" element={<TwitterCourses />} />
        <Route path='/ContentLibrary' element={<ContentLibrary/>}/>
      <Route path='/ContentLibraryCards' element={<ContentLibraryCards/>}/>
        </Route>
        <Route path='/' element={<Signin/>}/>
        <Route path='/InfoContentLibrary' element={<InfoContentLibrary/>}/>
        
      </Routes>
    </Router>
  );
}

export default App;
